var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.productsArray,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.productName",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"8","md":"10"}},[_c('span',{staticClass:"text-caption osg-black--text"},[_vm._v(_vm._s(item.productName))])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"4","md":"2"}},[_c('span',{class:['text-button', 
              {'primary--text': item.isApproved,
              'error--text': !item.isApproved}]},[_vm._v(" "+_vm._s(item.status)+" ")])])],1)]}},{key:"item.unitPrice",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.currencyCode)+" "+_vm._s(item.unitPrice))])]}},{key:"item.quantity",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.quantity))])]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t('addAuthorization.customObjectFields.drugProduct.footer.total'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.getTotalPrice()))]),_c('th',[_vm._v(" "+_vm._s(_vm.getTotalQuantity()))])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }